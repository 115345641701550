var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "nums-tips" }, [
    _c("img", { attrs: { src: _vm.blueSrc } }),
    _vm._v(" 共搜索到 "),
    _c("span", [_vm._v(_vm._s(_vm.nums))]),
    _vm._v("条数据 ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }