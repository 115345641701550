
import { Component, Prop, Vue } from 'vue-property-decorator'
import blueTips from '@/assets/images/blue_tips.png'

@Component({
  name: 'numsTips'
})
export default class extends Vue {
  @Prop({ default: 0 }) private nums?: any
  private blueSrc: string = blueTips
}
